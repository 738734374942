import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import App from "./App";

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }
  body, html {
    height: 100%;
    font-family: sans-serif;
  }
  body, ul, p {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: rgb(0, 42, 246);

    &:active {
      color: rgb(246, 42, 0);
    }
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <Router>
      <Route path={["/:year?/:month?"]}>
        <App />
      </Route>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
