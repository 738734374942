import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { getMonthName } from "./helpers";

const Container = styled.div`
  background: rgba(0, 42, 246);
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Year = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-evenly;

  padding: 0.5rem 0;
  width: 800px;
`;

const Month = styled.li``;

const Link = styled(NavLink)`
  display: block;
  padding: 0.5rem;
  color: #fff;
  border-radius: 4px;

  &.active,
  &:active {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
`;

export const Months = () => {
  const d = new Date();
  const year = d.getFullYear();
  const months = [...Array(12).keys()];

  return (
    <Container>
      <Year>
        {months.map((m) => (
          <Month key={`m_${m}`}>
            <Link to={`/${year}/${m + 1}`}>{getMonthName(m)}</Link>
          </Month>
        ))}
      </Year>
    </Container>
  );
};
