import React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { getDaysInMonth, getDateString, getWeekNumber } from "./helpers";
import { Months } from "./Months";

const StyledDay = styled.li`
  height: 75px;
  font-size: 0.75rem;
  color: #333;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${(p) => p.outside && `color: #ccc;`}
  ${(p) => p.sunday && `color: #d00;`}
  ${(p) =>
    p.outside && p.sunday && `color: #dcc;`}

  span {
    display: block;
    font-size: 1.5rem;
    border-radius: 100%;
    line-height: 48px;
    width: 48px;
    height: 48px;

    ${(p) => p.today && `background: papayawhip;`}
  }
`;

const Day = ({ day, date }) => {
  const currentMonth = date.getMonth();
  const dayMonth = day.getMonth();
  const dayInMonth = day.getDate();
  const dayInWeek = day.getDay();
  return (
    <>
      {dayInWeek === 1 && <StyledDay>{getWeekNumber(day)}</StyledDay>}
      <StyledDay
        today={getDateString(new Date()) === getDateString(day)}
        sunday={dayInWeek === 0}
        outside={currentMonth !== dayMonth}
      >
        <span>{dayInMonth}</span>
      </StyledDay>
    </>
  );
};

const Month = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-radius: 4px;
`;

const Heading = styled.li`
  padding: 1.5rem 0 0;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
`;

const Title = styled.h1`
  text-align: center;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  margin: auto;
  width: 800px;
`;

const NavLink = styled(Link)`
  width: 25%;

  &:last-child {
    text-align: right;
  }
`;

const App = () => {
  const { year, month } = useParams();
  const customDate = year && month && `${year}-${month}`;
  const d = customDate ? new Date(customDate) : new Date();
  const yearNumber = customDate ? parseInt(year) : d.getFullYear();
  const monthNumber = customDate ? parseInt(month) : d.getMonth() + 1;
  const prevMonth = new Date(
    `${d.getFullYear()}-${d.getMonth() === 0 ? 12 : d.getMonth()}`
  ).toLocaleDateString("sv-SE", {
    month: "long",
  });
  const nextMonth = new Date(
    `${d.getFullYear()}-${d.getMonth() === 11 ? 1 : d.getMonth() + 2}`
  ).toLocaleDateString("sv-SE", {
    month: "long",
  });
  const cDays = getDaysInMonth(d.getMonth(), d.getFullYear());
  const pDays =
    cDays[0].getUTCDay() > 0
      ? getDaysInMonth(
          d.getMonth() === 0 ? 11 : d.getMonth() - 1,
          d.getMonth() === 0 ? d.getFullYear() - 1 : d.getFullYear()
        ).slice(-cDays[0].getUTCDay())
      : [];
  const nDays = getDaysInMonth(
    d.getMonth() === 11 ? 0 : d.getMonth() + 1,
    d.getMonth() === 11 ? d.getFullYear() + 1 : d.getFullYear()
  ).slice(0, 6 - cDays[cDays.length - 1].getUTCDay());
  const days = [...pDays, ...cDays, ...nDays];
  const prevLink = `/${monthNumber === 1 ? yearNumber - 1 : yearNumber}/${
    monthNumber === 1 ? 12 : monthNumber - 1
  }`;
  const nextLink = `/${monthNumber === 12 ? yearNumber + 1 : yearNumber}/${
    monthNumber === 12 ? 1 : monthNumber + 1
  }`;

  return (
    <>
      <Months />
      <Container>
        <Head>
          <NavLink to={prevLink}>&larr; {prevMonth}</NavLink>
          <Title>{d.toLocaleDateString("sv-SE", { month: "long" })}</Title>
          <NavLink to={nextLink}>{nextMonth} &rarr;</NavLink>
        </Head>

        <Month>
          <Heading>v.</Heading>
          <Heading>måndag</Heading>
          <Heading>tisdag</Heading>
          <Heading>onsdag</Heading>
          <Heading>torsdag</Heading>
          <Heading>fredag</Heading>
          <Heading>lördag</Heading>
          <Heading>söndag</Heading>
          {days.map((day, i) => (
            <Day key={`day:${i}`} day={day} date={d} />
          ))}
        </Month>
      </Container>
    </>
  );
};

export default App;
