export const getDaysInMonth = (month, year) =>
  new Array(31)
    .fill("")
    .map((_, i) => new Date(year, month, i + 1))
    .filter((v) => v.getMonth() === month);

export const getDateString = (date) =>
  `${date.getYear()}${date.getMonth()}${date.getDate()}`;

export const getWeekNumber = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

export const getMonthName = (month) =>
  new Date(0, month).toLocaleDateString("sv-SE", {
    month: "long",
  });
